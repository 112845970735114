
import { Component, mixins, Vue, Prop, Watch } from "nuxt-property-decorator";
import VueSkeletonLoader from "skeleton-loader-vue";

@Component({
  components: {
    "skeleton-loader-vue": VueSkeletonLoader,
  },
})
export default class SkeletonHotNews extends Vue { }
