var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper-question" }, [
    _c("div", { staticClass: "list-question border_bottom" }, [
      _c(
        "div",
        {
          staticClass: "d-flex flex-row col-md-12 px-0 justify-content-between",
        },
        [
          _c(
            "div",
            { staticClass: "col-md-3 px-0" },
            [
              _c("skeleton-loader-vue", {
                attrs: {
                  width: "100%",
                  height: "96px",
                  animation: "fade",
                  rounded: true,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-9 d-flex flex-column" },
            [
              _c("skeleton-loader-vue", {
                staticClass: "mb-1",
                attrs: {
                  width: "100%",
                  height: "45px",
                  animation: "fade",
                  rounded: true,
                },
              }),
              _vm._v(" "),
              _c("skeleton-loader-vue", {
                staticClass: "mb-1",
                attrs: {
                  width: "80%",
                  height: "15px",
                  animation: "fade",
                  rounded: true,
                },
              }),
              _vm._v(" "),
              _c("skeleton-loader-vue", {
                staticClass: "mb-1",
                attrs: {
                  width: "10%",
                  height: "15px",
                  animation: "fade",
                  rounded: true,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }